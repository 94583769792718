import { Button, Link, Picture, SquareLogo } from '@troon/ui';
import { Match, Switch } from 'solid-js';
import { imageUrl } from '../modules/images';
import { iOSAppStoreUrl } from '../modules/urls';
import { getConfigValue } from '../modules/config';
import { TopoBg } from './topo-bg';

type Props = {
	variant?: 'banner' | 'xl-banner';
};

export function MobileUpsellBanner(props: Props) {
	return (
		<Switch>
			<Match when={props.variant === 'xl-banner'}>
				<XlBanner />
			</Match>
			<Match when={!props.variant || props.variant === 'banner'}>
				<Banner />
			</Match>
		</Switch>
	);
}

function Banner() {
	return (
		<div class="flex flex-row flex-wrap items-center justify-stretch gap-8 rounded bg-brand-700 p-6 text-white">
			<div class="size-16 shrink-0 rounded bg-brand p-4">
				<SquareLogo />
			</div>
			<div class="grow">
				<h2 class="mb-2 text-xl font-semibold">Download the Troon Rewards iPhone app</h2>
				<p>Book tee times and earn rewards at your favorite Troon courses.</p>
			</div>
			<div class="grow">
				<Button as={Link} href={iOSAppStoreUrl} appearance="secondary-current" target="_blank">
					Download
				</Button>
			</div>
		</div>
	);
}

function XlBanner() {
	return (
		<div class="relative flex flex-row flex-wrap justify-between gap-x-4 gap-y-6 rounded bg-brand bg-gradient-to-r from-neutral-950 to-brand-700 p-6 text-white lg:flex-nowrap lg:gap-x-8 lg:px-20 lg:py-8">
			<div class="z-10 flex w-full grow flex-col items-start justify-center gap-4 pe-16 lg:grow-0 lg:basis-5/12">
				<h2 class="mb-2 text-3xl font-semibold md:text-4xl">The easiest way to book with your Troon Card.</h2>
				<p class="text-lg">
					Download the all-new Troon Golf app to seamlessly book tee times at your favorite courses.
				</p>
				<div>
					<Link href={iOSAppStoreUrl} target="_blank">
						<img
							src={`https://${getConfigValue('MAP_HOST')}/assets/images/app-store-lg2x.png`}
							alt="Available on the App Store"
							width="190px"
							height="58px"
							loading="lazy"
						/>
					</Link>
				</div>
			</div>
			<div class="-mb-6 flex max-w-md shrink grow flex-row items-end justify-end lg:-mb-8 lg:max-w-none lg:shrink-0 lg:basis-5/12">
				<div class="absolute inset-y-0 right-0 z-0 h-full opacity-20">
					<TopoBg />
				</div>
				<Picture
					class="relative z-10"
					src={imageUrl('/photos/banner-mockup-20240730.png')}
					alt="Find your next tee time with the Troon Rewards app on your iPhone"
					sizes={[
						[307, 274],
						[615, 549],
					]}
					width={615}
					height={549}
					loading="lazy"
				/>
			</div>
		</div>
	);
}
